import stl from './NavButton.module.scss'

interface Props {
  isMenuExpanded: boolean
  visible: boolean
  onClick: () => void
}

const NavButton = ({ isMenuExpanded, visible, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      className={stl.navBtn}
      type="button"
      aria-hidden={visible}
      // eslint-disable-next-line
      aria-label={isMenuExpanded ? 'close' : 'open' + 'navigation menu'}
    >
      <svg
        className={stl.icon}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 17 14"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 1h15M1 7h15M1 13h15"
        />
      </svg>
    </button>
  )
}

export default NavButton
