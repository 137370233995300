import { ReactNode } from 'react'
import clsx from 'clsx'

import Button from '../button'

import stl from './HeroSection.module.scss'

interface Props {
  flip: boolean
  title: string
  desc: string
  btnLabel: string
  btnVariant: string
  btnIcon?: ReactNode
  imgSrc: string
  imgAlt: string
  divider: boolean
}

const HeroSection = ({
  flip,
  title,
  desc,
  btnLabel,
  btnVariant,
  btnIcon,
  imgSrc,
  imgAlt,
  divider,
}: Props) => (
  <section className={clsx(stl.heroSection, flip && stl.flip)}>
    <div className={stl.content}>
      <h1>{title}</h1>
      {divider && <div className={stl.divider} />}
      <p>{desc}</p>
      <Button label={btnLabel} variant={btnVariant} icon={btnIcon} />
    </div>
    <div className={stl.imgContainer}>
      <img src={imgSrc} alt={imgAlt} />
    </div>
  </section>
)

export default HeroSection
