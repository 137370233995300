import HeroSection from '../hero-section'
import Navbar from '../navbar'

import stl from './Header.module.scss'

const Header = () => (
  <header className={stl.header}>
    <Navbar />
    <HeroSection
      flip={false}
      title="Virtual healthcare for you"
      desc="Trafalgar provides progressive, and affordable healthcare, accessible on mobile and online for everyone"
      btnLabel="Consult today"
      btnVariant="primary"
      imgSrc="illustration.png"
      imgAlt="A healthcare insurance illustration showing a doctor and a patient discussing medical coverage options."
      divider={false}
    />
  </header>
)

export default Header
