import { useEffect, useState } from 'react'
import clsx from 'clsx'

import Logo from '../logo'
import NavButton from '../nav-button'

import stl from './Navbar.module.scss'

const Navbar = () => {
  const [expand, setExpand] = useState(false)
  const [width, setWidth] = useState(1000)
  const [path, setPath] = useState('Home')

  useEffect(() => {
    width > 640 && setExpand(false)
  }, [width])

  useEffect(() => {
    const measureWidth = () => setWidth(document.body.clientWidth)

    const handleHashChange = () =>
      setPath(window.location.hash.substring(1).split('%20').join(' '))

    handleHashChange()
    measureWidth()

    window.addEventListener('hashchange', handleHashChange)
    window.addEventListener('resize', measureWidth)
    return () => {
      window.removeEventListener('hashchange', handleHashChange)
      window.removeEventListener('resize', measureWidth)
    }
  }, [])

  const navlinks = ['Home', 'Find a Doctor', 'Apps', 'Testimonials', 'About us']

  return (
    <nav className={stl.navbar}>
      <div className={stl.container}>
        <Logo titleVisible={width >= 768} />
        {width <= 640 && (
          <NavButton
            isMenuExpanded={expand}
            visible={width <= 640}
            onClick={() => setExpand(!expand)}
          />
        )}
      </div>
      <ul
        aria-hidden={width <= 640 ? !expand : 'false'}
        aria-label="navigation-menu"
        className={clsx(stl.menu, expand && stl.expand)}
      >
        {navlinks.map(item => (
          <li
            key={item}
            className={item === path ? stl.active : ''}
            onClick={() => setExpand(false)}
          >
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Navbar
