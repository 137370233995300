import { ReactNode } from 'react'
import clsx from 'clsx'

import stl from './Button.module.scss'

interface Props {
  label: string
  variant: string
  onClick: () => void
  icon?: ReactNode
}

const Button = ({ label, variant, onClick, icon }: Props) => (
  <button className={clsx(stl.button, stl[`${variant}Btn`])} onClick={onClick}>
    {label}
    {icon}
  </button>
)

Button.defaultProps = {
  label: 'Button',
  variant: 'primary',
  onClick: () => console.log('Button clicked...'),
}

export default Button
