import ServiceCard from '../service-card'
import Button from '../button'

import { services } from '../../data'

import stl from './Services.module.scss'

const Services = () => (
  <section className={stl.services}>
    <div className={stl.sectionHeader}>
      <h3>Our services</h3>
      <div className={stl.divider} />
      <p>
        We provide to you the best choiches for you. Adjust it to your health
        needs and make sure your undergo treatment with our highly qualified
        doctors you can consult with us which type of service is suitable for
        your health
      </p>
    </div>
    <div className={stl.cardContainer}>
      {services.map((item, i) => (
        <ServiceCard key={i} {...item} />
      ))}
    </div>
    <Button label="Learn more" variant="secondary" />
  </section>
)

export default Services
