import Header from './components/header'
import HeroSection from './components/hero-section'
import Services from './components/services-section'

const App = () => (
  <>
    <Header />
    <main>
      <Services />
      <HeroSection
        flip
        title="Leading healthcare providers"
        desc="Our dedicated patient engagement app and web portal allow you to access information instantaneously (no tedeous form, long calls, or administrative hassle) and securely"
        btnLabel="Learn more"
        btnVariant="secondary"
        imgSrc="illustration_2.png"
        imgAlt="A doctor and nurse standing next to a tablet and syringes."
        divider
      />
      <HeroSection
        flip={false}
        title="Download our mobile apps"
        desc="Trafalgar provides progressive, and affordable healthcare, accessible on mobile and online for everyone. To us, it’s not just work. We take pride in the solutions we deliver"
        btnLabel="Download"
        btnVariant="secondary"
        imgSrc="illustration_3.png"
        imgAlt="A doctor using a tablet to access medical records and communicate with patients, showcasing healthcare technology in the digital age. #HealthTech"
        divider
        btnIcon={
          <svg
            width="11"
            height="16"
            viewBox="0 0 11 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.81951 1.05554L4.81951 12.6308L2.27646 10.0877C1.92569 9.73698 1.39954 9.73698 1.04878 10.0877C0.698014 10.4385 0.698014 10.9647 1.04878 11.3154L5.08258 15.3492C5.43335 15.7 5.95949 15.7 6.31026 15.3492L10.3441 11.3154C10.6948 10.9647 10.6948 10.4385 10.3441 10.0877C9.9933 9.73698 9.46715 9.73698 9.11638 10.0877L6.57333 12.6308L6.57333 1.05554C6.57333 0.617083 6.22257 0.178627 5.69642 0.178627C5.17027 0.178627 4.81951 0.617083 4.81951 1.05554Z"
              fill="#458FF6"
            />
          </svg>
        }
      />
    </main>
  </>
)

export default App
