import stl from './Logo.module.scss'

const Logo = ({ titleVisible }: { titleVisible: boolean }) => (
  <div className={stl.logo}>
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20.5" cy="20.5" r="20.5" fill="#458FF6" />
      <path
        d="M19.344 15.244H13.312V12.488H28.782V15.244H22.724V31H19.344V15.244Z"
        fill="white"
      />
    </svg>
    {titleVisible && <span className={stl.logoTitle}>Trafalgar</span>}
  </div>
)

export default Logo
