export const services = [
  {
    imgSrc: 'search-doctor.png',
    title: 'Search doctor',
    desc: 'Choose your doctor from thousands of specialist, general, and trusted hospitals',
  },
  {
    imgSrc: 'online-pharmacy.png',
    title: 'Online pharmacy',
    desc: 'Buy  your medicines with our mobile application with a simple delivery system',
  },
  {
    imgSrc: 'consultation.png',
    title: 'Consultation',
    desc: 'Free consultation with our trusted doctors and get the best recomendations',
  },
  {
    imgSrc: 'details-info.png',
    title: 'Details info',
    desc: 'Free consultation with our trusted doctors and get the best recomendations',
  },
  {
    imgSrc: 'emergency-care.png',
    title: 'Emergency care',
    desc: 'You can get 24/7 urgent care for yourself or your children and your lovely family',
  },
  {
    imgSrc: 'tracking.png',
    title: 'Tracking',
    desc: 'Track and save your medical history and health data ',
  },
]
