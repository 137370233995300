import stl from './ServiceCard.module.scss'

interface Props {
  imgSrc: string
  title: string
  desc: string
}

const ServiceCard = ({ imgSrc, title, desc }: Props) => (
  <div className={stl.serviceCard}>
    <div className={stl.imgContainer}>
      <img src={imgSrc} alt={imgSrc.split('.')[0]} />
    </div>
    <div className={stl.textContainer}>
      <h4>{title}</h4>
      <p>{desc}</p>
    </div>
  </div>
)

ServiceCard.defaultProps = {
  imgSrc: 'details-info.png',
  title: 'Details info',
  desc: ' Free consultation with our trusted doctors and get the best recomendations',
}

export default ServiceCard
